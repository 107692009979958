<template>
  <div class="container-md">
    <div class="row">
      <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
        <!-- {{ form }} -->
        <form @submit="submitHandler" novalidate autocomplete="off" class="form">
          <div class="mb-3" v-if="error">
            <div class="alert alert-danger">
              <span>{{ error }}</span>
            </div>
          </div>
          <div class="mb-3">
            <label for="birthDate" class="form-label">{{ $t('birthDate') }}</label>
            <input type="text" v-maska="'##.##.####'" placeholder="01.09.1991" v-model="form.birthDate"
                   pattern="[0-9.]*" inputmode="numeric"
                   :class="'form-control' + (v$.form.birthDate.$errors.length > 0 ? ' is-invalid' : '')"
                   aria-describedby="birthDateMessage"
                   id="birthDate">
            <div v-if="v$.form.birthDate.$errors.length > 0" id="birthDateMessage" class="invalid-feedback">
              {{ getValidationErrorText(v$.form.birthDate.$errors[0].$validator) }}
            </div>
          </div>
          <div class="mb-3">
            <label for="passport" class="form-label">{{ $t('passport') }}</label>
            <input type="text" v-maska="'AA #######'" placeholder="AA 0000000" v-model="form.passport"
                   :class="'form-control' + (v$.form.passport.$errors.length > 0 ? ' is-invalid' : '') "
                   aria-describedby="passportMessage"
                   id="passport">
            <div v-if="v$.form.passport.$errors.length > 0" id="passportMessage" class="invalid-feedback">
              {{ getValidationErrorText(v$.form.passport.$errors[0].$validator) }}
            </div>
          </div>
          <div class="mb-3">
            <label for="phone" class="form-label">{{ $t('phone') }}</label>
            <input type="text" v-maska="'+998 ## ### ## ##'" placeholder="+ 998 000 00 00" v-model="form.phone"
                   pattern="[0-9 ]*" inputmode="numeric"
                   :class="'form-control' + (v$.form.phone.$errors.length > 0 ? ' is-invalid' : '') "
                   aria-describedby="phoneMessage"
                   id="phone">
            <div v-if="v$.form.phone.$errors.length > 0" id="phoneMessage" class="invalid-feedback">
              {{ getValidationErrorText(v$.form.phone.$errors[0].$validator) }}
            </div>
          </div>
          <div class="mb-3">
            <div :class="'form-check' + (agreementError ? ' is-invalid' : '') ">
              <input class="form-check-input" type="checkbox" v-model="form.agreement" @change="agreementHandler"
                     id="agreement" aria-describedby="agreementMessage">
              <label class="form-check-label" for="agreement" v-html="$t('agreement')">
              </label>
            </div>
            <div v-if="agreementError === true" id="agreementMessage" class="invalid-feedback">
              {{ $t('error_agreement_required') }}
            </div>
          </div>
          <div class="mb-3 text-center">
            <button type="submit" class="btn btn-success">{{ $t('continue') }}</button>
          </div>
        </form>
        <Loader :state="loading"></Loader>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from './Loader.vue'
import useVuelidate from '@vuelidate/core'
import {required, minLength} from '@vuelidate/validators'

export default {
  components: {
    Loader,
  },
  inject    : ['$api_base_path'],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      loading       : true,
      error         : null,
      form          : {
        phone    : document.cookie.replace(/(?:(?:^|.*;\s*)phone\s*\=\s*([^;]*).*$)|^.*$/, "$1") || ' ',
        birthDate: document.cookie.replace(/(?:(?:^|.*;\s*)birthDate\s*\=\s*([^;]*).*$)|^.*$/, "$1") || null,
        passport : document.cookie.replace(/(?:(?:^|.*;\s*)passport\s*\=\s*([^;]*).*$)|^.*$/, "$1") || null,
        agreement: false,
      },
      agreementError: null,
      formValidated : false,
    }
  },
  mounted() {
    //this.$store.dispatch('createAttempt');
    this.loading = false
  },
  validations() {
    return {
      form: {
        birthDate: {
          required,
          min: minLength(10)
        },
        passport : {
          required,
          min: minLength(10),
        },
        phone    : {
          required,
          min: minLength(10)
        }
      }
    }
  },
  methods: {
    getValidationErrorText: function (v) {
      return this.$t('validation_' + v)
    },
    agreementHandler      : function () {
      this.agreementError = !this.form.agreement
    },
    submitHandler         : function (e) {
      e.preventDefault()
      const _self = this;
      _self.v$.$validate() // checks all inputs
      _self.agreementError = !_self.form.agreement
      if (_self.agreementError) {
        return;
      }
      if (_self.v$.$error) { // if ANY fail validation
        return;
      }
      _self.formValidated = true;
      _self.$store.commit('setForm', _self.form);
      const payload   = {
        ref_id         : _self.$store.getters.refId,
        agreed_on_terms: _self.form.agreement,
        birth_date     : _self.form.birthDate,
        passport       : _self.form.passport,
        phone          : _self.form.phone,
      };
      //add cookies 1 hour
      document.cookie = "phone=" + _self.form.phone + ";max-age=3600";
      document.cookie = "birthDate=" + _self.form.birthDate + ";max-age=3600";
      document.cookie = "passport=" + _self.form.passport + ";max-age=3600";
      _self.loading   = true
      _self.error     = null
      _self.axios.post(_self.$api_base_path + "/api/store", payload, {
        headers: {
          'Accept'         : 'application/json',
          'Content-Type'   : 'application/json',
          'Accept-Language': _self.$i18n.locale
        }
      }).then((response) => {
        if (response.data.success) {
          this.$emit('passportComplete')
          _self.loading = false
        } else {
          _self.error   = response.data.error
          _self.loading = false
        }
      })
    }
  }
}
</script>